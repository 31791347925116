.services-container {
    padding: 4rem 1rem;
    background-color: #f8f9fa;
  }
  
  .main-heading {
    font-size: 2.5rem;
    font-weight: 700;
    color: #212529;
    margin-bottom: 1rem;
  }
  
  .subtitle {
    max-width: 1000px;
    margin: 0 auto 2rem;
    font-size: 1rem;
    line-height: 1.6;
    color: #495057;
  }
  
  .service-card {
    background-color: #fff;
    border-radius: 16px;
    padding: 2rem;
    height: 100%;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.04);
    transition: transform 0.3s ease;
  }
  
  .service-title {
    margin-bottom: 1rem;
  }
  
  .service-title h2 {
    font-size: 1.25rem;
    font-weight: 600;
    color: #212529;
    display: flex;
    align-items: center;
  }
  
  .arrow {
    font-size: 1rem;
    margin-left: 0.5rem;
    opacity: 0.7;
  }
  
  .service-description {
    font-size: 0.95rem;
    color: #495057;
    line-height: 1.6;
    margin-bottom: 0;
  }
  
  .text-pink {
    color: #ff3366;
    text-decoration: underline;
    font-weight: 500;
  }
  
  @media (max-width: 768px) {
    .main-heading {
      font-size: 2rem;
    }
    
    .service-card {
      padding: 1.5rem;
    }
  }
  
  @media (max-width: 576px) {
    .main-heading {
      font-size: 1.75rem;
    }
    
    .subtitle {
      font-size: 0.9rem;
    }
    
    .service-title h2 {
      font-size: 1.1rem;
    }
    
    .service-description {
      font-size: 0.9rem;
    }
    
    .services-container {
      padding: 3rem 0.75rem;
    }
  }