.job-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 300px;
    margin: 20px;
  }
  
  .job-card-header {
    margin-bottom: 15px;
  }
  
  .job-card-header h2 {
    font-size: 20px;
    margin: 0;
  }
  
  .company-name {
    color: #888;
    font-size: 14px;
    margin: 5px 0;
  }
  
  .location {
    color: #555;
    font-size: 14px;
  }
  
  .job-card-body {
    margin-bottom: 20px;
  }
  
  .job-description {
    color: #333;
    font-size: 15px;
    line-height: 1.5;
  }
  
  .job-card-footer {
    display: flex;
    justify-content: flex-end;
  }
  
  .apply-btn {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .apply-btn:hover {
    background-color: #0056b3;
  }

  /* job description */

/* Main container for job description */
.job-description.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Style for each job description */
.description-header {
  border-bottom: 2px solid #f1f1f1;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.description-header h2 {
  font-size: 28px;
  font-weight: bold;
  color: #333;
}

.company-name {
  font-size: 20px;
  color: #555;
}

.location, .job-type, .experience-level {
  font-size: 14px;
  color: #777;
}

/* Body section styling */
.description-body {
  font-size: 16px;
  color: #333;
  line-height: 1.6;
}

.job-description-text {
  margin-bottom: 20px;
}

.section-title {
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
  color: #333;
}

.responsibilities-list, .skills-list, .qualifications-list {
  list-style-type: disc;
  margin-left: 20px;
}

.list-item {
  margin-bottom: 10px;
}

/* Footer section (can be customized for actions) */
.description-footer {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.description-footer button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.description-footer button:hover {
  background-color: #0056b3;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .description-header h2 {
    font-size: 22px;
  }

  .company-name {
    font-size: 18px;
  }

  .location, .job-type, .experience-level {
    font-size: 12px;
  }

  .description-body {
    font-size: 14px;
  }

  .section-title {
    font-size: 16px;
  }

  .list-item {
    font-size: 14px;
  }
  
  .description-footer button {
    padding: 8px 16px;
    font-size: 14px;
  }
}

/* Smaller devices (mobile) */
@media (max-width: 480px) {
  .job-description.container {
    padding: 15px;
  }

  .description-header h2 {
    font-size: 20px;
  }

  .company-name {
    font-size: 16px;
  }

  .location, .job-type, .experience-level {
    font-size: 10px;
  }

  .description-body {
    font-size: 12px;
  }

  .section-title {
    font-size: 14px;
  }

  .list-item {
    font-size: 12px;
  }

  .description-footer button {
    padding: 6px 12px;
    font-size: 12px;
  }
}
