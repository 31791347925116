.startup-software-container {
    background-color: #1e1e1e;
    color: white;
    padding: 50px 20px;
    font-family: 'Arial', sans-serif;
    max-width: 1200px;
    margin: 1rem auto;
    border-radius: 15px;
    
  }
  
  .startup-software-header {
    text-align: center;
    margin-bottom: 50px;
    padding: 0 15px;
    
  }

  .startup-software-header h1 {
    color: white;
  }
  
  .startup-software-header h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    font-weight: 600;
  }
  
  .startup-software-header p {
    font-size: 1rem;
    line-height: 1.6;
    max-width: 900px;
    margin: 0 auto;
    color: rgba(255, 255, 255, 0.8);
  }
  
  .startup-software-content {
    position: relative;
    padding: 20px 0;
  }
  
  .card {
    background-color: rgba(40, 40, 40, 0.8);
    border-radius: 10px;
    padding: 30px 25px;
    height: 300px;
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease;
    
  }
  
  
  
  .card-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    font-size: 24px;
  }
  
  .card h3 {
    font-size: 1.5rem;
    margin-bottom: 15px;
    font-weight: 600;
  }
  
  .card p {
    font-size: 0.95rem;
    line-height: 1.6;
    color: rgba(12, 11, 11, 0.911);
    flex-grow: 1;
    overflow-y: auto;
    scrollbar-width: none;

  }
  
  .startup-software-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    padding: 0 15px;
  }
  
  .connect-btn {
    display: flex;
    align-items: center;
    background-color: #f89e52;
    color: #000;
    border: none;
    border-radius: 25px;
    padding: 12px 25px;
    font-size: 0.95rem;
    font-weight: 600;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease;
  }
  
  .btn-text {
    z-index: 1;
    margin-right: 5px;
  }
  
  .btn-icon {
    z-index: 1;
    font-size: 1rem;
  }
  
  .btn-hover-effect {
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: #3482ff;
    transition: all 0.3s ease;
    z-index: 0;
  }
  
  .connect-btn:hover .btn-hover-effect,
  .nav-btn:hover .btn-hover-effect {
    left: 0;
  }
  
  .navigation-buttons {
    display: flex;
    gap: 10px;
  }
  
  .nav-btn {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.3);
    background: transparent;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease;
  }
  
  .nav-btn span {
    position: relative;
    z-index: 1;
    transition: color 0.3s ease;
  }
  
  .nav-btn:hover span {
    color: #1e1e1e;
  }
  
  /* Icon colors */
  .card:nth-child(3n+1) .card-icon {
    background-color: rgba(180, 150, 220, 0.3);
  }
  
  .card:nth-child(3n+2) .card-icon {
    background-color: rgba(150, 220, 150, 0.3);
  }
  
  .card:nth-child(3n+3) .card-icon {
    background-color: rgba(150, 200, 220, 0.3);
  }
  
  /* Swiper customization */
  .swiper-pagination-bullet {
    background: white;
    opacity: 0.5;
  }
  
  .swiper-pagination-bullet-active {
    opacity: 1;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .startup-software-container{
      margin: 2rem ;
    }
    .startup-software-header h1 {
      font-size: 2rem;
    }
    
    .startup-software-footer {
      flex-direction: column;
      gap: 20px;
    }
    
    .card {
      /* height: auto; */
      min-height: 280px;
    }
  }
  
  @media (max-width: 576px) {
    .startup-software-header h1 {
      font-size: 1.8rem;
    }
    
    .startup-software-header p {
      font-size: 0.9rem;
    }
  }