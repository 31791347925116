.banner-container {
    background-color: #1a1e21;
    color: white;
    padding: 2rem;
    border-radius: 1rem;
    overflow: hidden;
    margin: 2rem;
    /* margin-left: 60px;
    margin-right: 60px; */
}

.banner-content {
    display: flex;
    align-items: center;
}

.text-content {
    padding: 1.5rem;
}

.banner-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: white;
}

.banner-description {
    font-size: 1rem;
    margin-bottom: 1rem;
    opacity: 0.9;
    color: rgb(233, 227, 227);
    line-height: 1.3;
    font-weight: 300;
}

.talk-button {
    background-color: #f89e52;
    color: #000;
    border: none;
    padding: 0.8rem 1.5rem;
    border-radius: 2rem;
    font-weight: bold;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease;
    cursor: pointer;
}

.button-text {
    position: relative;
    z-index: 2;
}

.button-icon {
    margin-left: 0.5rem;
    position: relative;
    z-index: 2;
}

.hover-effect {
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: #3482ff;
    transition: all 0.4s ease;
    z-index: 1;
}

.talk-button:hover .hover-effect {
    left: 0;
}

.talk-button:not(:hover) .hover-effect {
    left: -100%;
}

.image-section {
    position: relative;
    display: flex;
    justify-content: center;
}

.image-container {
    position: relative;
    width: 100%;
    max-width: 400px;
}

.image-container-img {
    /* background-color: #000; */
    padding-left: 0;
}

.businessman-image {
    width: 100%;
    height: auto;
    border-radius: 50%;
    object-fit: cover;
    aspect-ratio: 1/1;
}

.feature-tags {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
}

.feature-tag {
    background-color: rgba(33, 37, 41, 0.8);
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 1.5rem;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.tag-icon {
    font-style: normal;
}

@media (max-width: 768px) {
    .banner-container {
        padding: 1.5rem;
    }

    .banner-title {
        font-size: 1.8rem;
    }

    .banner-description {
        font-size: 1rem;
    }

    .feature-tags {
        position: static;
        transform: none;
        margin-top: 1rem;
    }

    .image-container {
        margin: 0 auto;
    }

    .businessman-image {
        max-width: 250px;
        margin: 0 auto;
        display: block;
    }
}