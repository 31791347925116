/* HeroSection.css */
.hero-section {
    position: relative;
    background-color: #fff;
    overflow: hidden;
    padding: 70px 0;
  }
  
  .hero-section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, #f5f5f5 0%, #e9e9e9 100%);
    opacity: 0.3;
    z-index: 0;
  }
  
  .hero-section .container {
    position: relative;
    z-index: 1;
  }
  
  /* Typography */
  .hero-section h1 {
    font-size: 2.5rem;
    color: #212529;
    line-height: 1.2;
    margin-bottom: 1.5rem;
  }
  
  .hero-section p {
    font-size: 1rem;
    color: #4a4a4a;
    line-height: 1.3;
  }
  
  /* Share Button */
  .button-wrapper {
    position: relative;
    display: inline-block;
  }
  
  .share-button {
    display: flex;
    align-items: center;
    background-color: #f89e52;
    color: white;
    border: none;
    border-radius: 22px;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 500;
    position: relative;
    overflow: hidden;
    z-index: 1;
    transition: color 0.3s ease;
  }

  .share-button span{
    transform: uppercase;
  }
  
  .share-button::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: #3482ff;
    transition: width 0.3s ease;
    z-index: -1;
  }
  
  .share-button:hover {
    color: #212529;
  }
  
  .share-button:hover::before {
    width: 100%;
  }
  
  /* Stats Section */
  .stats-container {
    margin-top: 60px;
  }
  
  .stat-item h3 {
    font-size: 2.5rem;
    color: #212529;
    margin-bottom: 5px;
  }
  
  .stat-text {
    font-size: 0.85rem;
    color: #4a4a4a;
    line-height: 1.4;
  }
  
  /* UI Visualization */
  .visualization-container {
    position: relative;
    width: 100%;
    max-width: 420px;
  }
  
  .ui-card {
    position: relative;
    background-color: #3f51b5;
    border-radius: 12px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
    width: 100%;
    aspect-ratio: 4/3;
    padding: 20px;
  }
  
  /* Browser dots */
  .browser-dots {
    display: flex;
    justify-content: flex-end;
    gap: 6px;
    margin-bottom: 20px;
  }
  
  .browser-dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }
  
  .browser-dot.green { background-color: #4CAF50; }
  .browser-dot.yellow { background-color: #FFC107; }
  .browser-dot.red { background-color: #F44336; }
  
  /* Mobile Phone */
  .mobile-phone {
    position: absolute;
    top: 20%;
    left: 8%;
    width: 28%;
    height: 50%;
    background-color: #2196F3;
    border-radius: 12px;
    border: 4px solid #1E88E5;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    overflow: hidden;
  }
  
  .phone-screen {
    height: 50%;
    background-color: white;
    margin: 4px;
    border-radius: 6px;
    padding: 6px;
  }
  
  .message-line {
    height: 8px;
    background-color: #E3F2FD;
    border-radius: 4px;
    margin-bottom: 4px;
    width: 100%;
  }
  
  .message-line-short {
    height: 8px;
    background-color: #E3F2FD;
    border-radius: 4px;
    margin-bottom: 12px;
    width: 75%;
  }
  
  .reply-bubble {
    height: 15px;
    width: 40px;
    background-color: #FFC107;
    border-radius: 4px;
    margin-left: auto;
  }
  
  .phone-apps {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 4px;
    padding: 6px;
  }
  
  .app-icon {
    width: 100%;
    aspect-ratio: 1;
    border-radius: 4px;
  }
  
  .app-icon.pink { background-color: #E91E63; }
  .app-icon.purple { background-color: #9C27B0; }
  .app-icon.yellow { background-color: #FFC107; }
  .app-icon.green { background-color: #4CAF50; }
  .app-icon.blue { background-color: #2196F3; }
  
  /* Pie Chart */
  .pie-chart {
    position: absolute;
    top: 15%;
    right: 10%;
    width: 35%;
    aspect-ratio: 1;
    border-radius: 50%;
    overflow: hidden;
  }
  
  .pie-segment {
    position: absolute;
  }
  
  .pie-segment.blue {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #2196F3;
  }
  
  .pie-segment.red {
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    background-color: #F44336;
  }
  
  .pie-segment.yellow {
    bottom: 0;
    right: 0;
    width: 50%;
    height: 50%;
    background-color: #FFC107;
  }
  
  .pie-segment.green {
    top: 0;
    left: 0;
    width: 25%;
    height: 50%;
    background-color: #4CAF50;
  }
  
  .pie-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 12%;
    height: 12%;
    background-color: black;
    border-radius: 50%;
    z-index: 2;
  }
  
  /* Bar Charts */
  .bar-charts {
    position: absolute;
    bottom: 20%;
    right: 12%;
    display: flex;
    align-items: flex-end;
    gap: 6px;
  }
  
  .bar {
    width: 12px;
    border-radius: 2px 2px 0 0;
  }
  
  .bar-red {
    height: 20px;
    background-color: #F44336;
  }
  
  .bar-blue {
    height: 35px;
    background-color: #2196F3;
  }
  
  .bar-green {
    height: 25px;
    background-color: #4CAF50;
  }
  
  .bar-yellow {
    height: 45px;
    background-color: #FFC107;
  }
  
  /* Gears */
  .gears {
    position: absolute;
    bottom: 16%;
    left: 20%;
  }
  
  .large-gear {
    position: relative;
    width: 50px;
    height: 50px;
    background-color: #FFC107;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .gear-center {
    width: 25px;
    height: 25px;
    background-color: #E6A800;
    border-radius: 50%;
  }
  
  .gear-tooth {
    position: absolute;
    background-color: #FFC107;
  }
  
  .gear-tooth.top {
    width: 8px;
    height: 16px;
    top: -8px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .gear-tooth.bottom {
    width: 8px;
    height: 16px;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .gear-tooth.left {
    width: 16px;
    height: 8px;
    left: -8px;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .gear-tooth.right {
    width: 16px;
    height: 8px;
    right: -8px;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .small-gear {
    position: absolute;
    width: 30px;
    height: 30px;
    background-color: #FF9800;
    border-radius: 50%;
    top: -10px;
    right: -10px;
  }
  
  /* Data lines */
  .data-lines {
    position: absolute;
    top: 50%;
    right: 10%;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .data-line {
    height: 8px;
    border-radius: 4px;
  }
  
  .yellow-line {
    width: 80px;
    background-color: #FFC107;
  }
  
  .blue-line {
    width: 110px;
    background-color: #90CAF9;
  }
  
  .green-line {
    width: 65px;
    background-color: #A5D6A7;
  }
  
  /* Responsive adjustments */
  @media (max-width: 991.98px) {
    .hero-section h1 {
      font-size: 2.2rem;
    }
    
    .visualization-container {
      margin-top: 30px;
      max-width: 380px;
    }
  }
  
  @media (max-width: 767.98px) {
    .hero-section h1 {
      font-size: 2rem;
    }
    
    .stat-item h3 {
      font-size: 2rem;
    }
    
    .stat-text {
      font-size: 0.8rem;
    }
    
    .visualization-container {
      max-width: 320px;
    }
  }
  
  @media (max-width: 575.98px) {
    .hero-section{
      padding-left: 1rem ;
      padding-right: 1rem ;
    }
    .hero-section h1 {
      font-size: 1.8rem;
    }
    
    .visualization-container {
      max-width: 100%;
    }
  }
  
  /* Counter animation */
  @keyframes countUp {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .stat-item h3 {
    animation: countUp 0.5s ease-out forwards;
  }